import React from 'react';
import { withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave-croped.png';

import impulse from '../../assets/img/references/impulse@2x.png';
import parisco from '../../assets/img/references/parisco.png';
import sba from '../../assets/img/references/sba@2x.png';
import stationF from '../../assets/img/references/station-f-logo.png';
import propTech from '../../assets/img/references/proptech.png';

const supportContent = [
  {
    img: parisco,
    alt: 'Parisco',
  },
  {
    img: impulse,
    alt: 'Impulse Partners',
  },
  {
    img: sba,
    alt: 'SBA',
  },
  {
    img: stationF,
    alt: 'Station F',
  },
  {
    img: propTech,
    alt: 'French PropTech',
  },
];

const SupportSection = () => {
  return (
    <section
      className="mt-2 mt-5-md pb-20 pt-30 bg-wave "
      style={{
        marginBottom: '-50px',
        backgroundImage: `url(${withPrefix(imgBg)})`,
      }}
    >
      <div className="container row">
        <div className="col-12 supports">
          <div className="bold roboto font-size-xxl font-size-xl-md text-align-center col-12">
            Ils nous soutiennent
          </div>
          <div className="support-container mr-auto ml-auto mt-10 mt-8-sm size-75">
            {supportContent.map((value, index) => (
              <div
                className="support flex justify-content-center align-items-center ml-auto mr-auto mt-5 mb-2"
                key={`support-${index}`}
              >
                <img src={value.img} alt={value.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportSection;
