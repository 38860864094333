import React from 'react';

import iconEntrepreneuriat from '../../assets/img/icons/entreprenariat.svg';
import iconCommunication from '../../assets/img/icons/communication.svg';
import iconProximit from '../../assets/img/icons/proximit.svg';

const valuesContent = [
  {
    img: iconEntrepreneuriat,
    title: "L'entrepreneuriat",
    content: 'Nous croyons fermement en la capacité d’action des individus',
  },
  {
    img: iconCommunication,
    title: 'Le dialogue',
    content:
      'Nous sommes persuadés que la communication est l’élément essentiel d’une société qui fonctionne ',
  },
  {
    img: iconProximit,
    title: 'La proximité ',
    content:
      'Il est nécessaire et souhaitable de valoriser les échanges locaux afin d’encourager le lien social local',
  },
];

const ValuesSection = () => {
  return (
    <section className="container mt-10 mt-5-md mb-10">
      <div className="row">
        <div className="col-12 values">
          <div className="bold roboto font-size-xxl font-size-xl-md text-align-center col-12">
            Nos valeurs
          </div>
          <div className="values-container mr-auto ml-auto mt-10 mt-8-sm size-75">
            {valuesContent.map((value, index) => (
              <div
                className="value ml-auto mr-auto mt-5-sm"
                key={`value-${index}`}
              >
                <img src={value.img} alt="icon Valeurs" />
                <div className="title font-size-lg bold pt-2">
                  {value.title}
                </div>
                <div className="content font-size-md2 pt-2">
                  {value.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;
