import React from 'react';
import { MainLayout } from '../components/Layout';
import { withPrefix } from 'gatsby';

import ValuesSection from '../components/Alacaza/ValuesSection';
import SupportSection from '../components/Alacaza/SupportSection';

import imgBg from '../assets/img/home/bg-wave.png';
import imgWater from '../assets/img/illustrations/water.png';
import imgLocProp from '../assets/img/illustrations/about-illu-2.png';
import imgPartnersList from '../assets/img/illustrations/partners-list.png';

export default () => {
  return (
    <MainLayout
      siteTitle="À propos d'Alacaza"
      siteDescription="Chez Alacaza, nous souhaitons redonner la main aux occupants et aux locataires !"
    >
      <section className="container mt-10 mt-5-md">
        <div className="row">
          <div className="col-12-sm text-align-center hide show-md pb-5-md">
            <img
              src={imgWater}
              alt="À l'origine du projet"
              className="text-align-center"
              style={{ maxHeight: `140px`, maxWidth: `140px` }}
            />
          </div>

          <div className="col-6 col-12-sm pl-10 pr-10 pl-3-md pr-3-md">
            <div className="bold roboto font-size-xxl font-size-xl-md">
              Origine du projet
            </div>
            <div className="font-size-md2 pt-10 text-color-purple-grey line-height-plus">
              <p className="italic text-color-purple pb-5">
                « Un 1er août, veille de départ en vacances, j'ai fait face à
                une importante fuite d'eau dans mon appartement. Mes voisins
                étaient absents, il m'était impossible de les joindre afin
                d'agir au plus vite. Ne faisant pas partie du conseil syndical,
                je ne possèdais pas les coordonnées des différents
                copropriétaires de mon immeuble. Et le syndic de copropriété ne
                répondait pas non plus à mes appels... »
              </p>
              <p>
                C'est ainsi qu'est née l'idée d'Alacaza : un outil pour
                améliorer et fluidifier la communication entre les différentes
                parties prenantes d'un immeuble est nécessaire afin de ne plus
                jamais se retrouver dans cette situation !
              </p>
              <p>
                Aujourd'hui, l'équipe d'Alacaza est composée d'entrepreneurs et
                collaborateurs passionnés, souhaitant avoir un impact positif :
                simplifier le quotidien des habitants au sein de leurs
                résidences et de leurs quartiers.
              </p>
            </div>
          </div>
          <div className="col-6 text-align-center hidden-sm">
            <img
              src={imgWater}
              alt="À l'origine du projet"
              style={{ maxHeight: `340px` }}
            />
          </div>
        </div>
      </section>

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-10 mt-5-md">
          <div className="row pt-10">
            <div className="col-6 text-align-center hidden-sm pt-10">
              <img
                src={imgLocProp}
                alt="Nous souhaitons redonner la main aux occupants, copropriétaires et locataires"
                style={{ maxHeight: `280px` }}
              />
            </div>
            <div className="col-6 col-12-sm pl-10 pr-10 pl-3-md pr-3-md">
              <div className="bold roboto font-size-xxl font-size-xl-md">
                Nous souhaitons redonner la main aux occupants, copropriétaires
                et locataires
              </div>

              <div className="block text-align-center hide show-md pt-10-md">
                <img
                  src={imgLocProp}
                  alt="Nous souhaitons redonner la main aux occupants, copropriétaires et locataires"
                  className="text-align-center"
                  style={{ maxHeight: `140px`, maxWidth: `340px` }}
                />
              </div>

              <div className="font-size-md2 pt-10 pr-20 pr-0-md text-color-purple-grey pb-10 line-height-plus">
                <p>
                  De nombreux rapports de forces régissent la vie quotidienne au
                  sein d’une résidence. Dans les logements en accession comme
                  dans les logements sociaux, le locataire éprouve des
                  difficultés à faire entendre sa voix. Il en est de même pour
                  les copropriétaires vis-à-vis leurs pairs ou du syndic de
                  copropriété.
                </p>
                <p>
                  Partant de ce constat, l’équipe d’Alacaza travaille pour
                  rééquilibrer ces rapports de forces au profit des résidents,
                  tout en valorisant les professionnels de l’immobilier. Cela
                  passe par une meilleure communication, davantage de
                  transparence et moins d’asymétrie d’information.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container mt-10 mt-5-md">
        <div className="row">
          <div className="col-6 col-12-sm pl-15 pr-10 pl-3-md pr-3-md">
            <div className="bold roboto font-size-xxl font-size-xl-md">
              Nous encourageons les alliances positives entre tous les acteurs
              de la résidence et du quartier
            </div>

            <div className="block text-align-center hide show-md pt-10-md">
              <img
                src={imgPartnersList}
                alt="Nous encourageons les alliances positives entre tous les acteurs de la résidence et du quartier"
                className="text-align-center"
                style={{ maxHeight: `400px`, maxWidth: `400px` }}
              />
            </div>

            <div className="font-size-md2 pt-10 text-color-purple-grey line-height-plus">
              <p>
                L’équipe d’Alacaza souhaite travailler en accord avec les
                professionnels de la résidence afin d’améliorer leur
                communication avec les résidents. L’application a de ce fait été
                pensé pour les gestionnaires – les syndics de copropriété tout
                comme les bailleurs sociaux – mais aussi pour les promoteurs,
                pour les gardiens, pour les membres du conseil syndical ou pour
                les prestataires de services.
              </p>
              <p>
                Alacaza simplifie et valorise le travail de l’ensemble de ces
                acteurs, tout en redonnant la main aux résidents sur les
                informations concernant leur lieu de vie.
              </p>
              <p>
                Grâce à un fil d’actualité spécifique et à une place de marché,
                les échanges locaux sont mis en avant. Ainsi, les commerces et
                les associations valorisent leurs activités. Les collectivités
                peuvent également rejoindre les citoyens utilisateurs afin de
                mieux communiquer avec ces derniers, grâce à un meilleur ciblage
                des destinataires.
              </p>
            </div>
          </div>
          <div className="col-6 text-align-center hidden-sm pr-10">
            <img
              src={imgPartnersList}
              alt="Nous encourageons les alliances positives entre tous les acteurs de la résidence et du quartier"
              style={{ maxHeight: `540px` }}
            />
          </div>
        </div>
      </section>

      <ValuesSection />
      <SupportSection />
    </MainLayout>
  );
};
